$(function() {
  // UI：スクロール
  $('#page-top a').smoothScroll();
  $('.localnav a').smoothScroll();
  $('.about #outline a, .about #siteinfo dt a').smoothScroll({offset: -25});
  //toTopの表示・非表示
  function displayToTop(){
    var scrollTop = $(window).scrollTop();
    if (scrollTop < 300) {
      $("#page-top").fadeOut();
    } else {
      $("#page-top").fadeIn();
    }
  }
  $(window).on("load scroll", displayToTop);

  // ナビゲーション
  $('.menu-trigger').on('click',function(){
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).next('ul').removeClass('open');
      $('.overlay').removeClass('open');
    } else {
      $(this).addClass('active');
      $(this).next('ul').addClass('open');
      $('.overlay').addClass('open');
    }
  });
  $('.overlay').on('click',function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('.menu-trigger').removeClass('active');
      $('header ul').removeClass('open');
    }
  });

  // ホーム：スライダー
  if ($('body').hasClass('home')){
      $('.slider ul').slick({
        autoplay:true,
        arrows: false,
        autoplaySpeed:5000,
        pauseOnHover: false,
        speed: 1005,
        slidesToShow:1,
        slidesToScroll:1,
        centerMode: true,
        centerPadding:'25%',
        responsive: [{
          breakpoint: 992,
          settings: {
            centerPadding:'20%'
          },
          breakpoint: 768,
          settings: {
            centerPadding:'10%'
          }
        }]
      });
  }

  // newsbox
  $('.newsbox').click(function(){
         if($(this).find("a").attr("target")=="_blank"){
             window.open($(this).find("a").attr("href"), '_blank');
         }else{
             window.location=$(this).find("a").attr("href");
         }
     return false;
     });

  // news詳細画像サイズ除去・youtube
  if ($('body').hasClass('news-detail')){
    $('#contents .section img').removeAttr('width height');
    $("iframe[src*='youtube.com']").each(function() {
    	$(this).wrap("<div class=\"video-wrapper\"></div>");
    });
  }
  // ひまわり広場詳細画像サイズ除去・youtube
  if ($('body').hasClass('himawarihiroba-detail')){
    $('#contents .section img').removeAttr('width height');
    $("iframe[src*='youtube.com']").each(function() {
    	$(this).wrap("<div class=\"video-wrapper\"></div>");
    });
  }

  // 園についてポップアップ
  $('.about #institutes').magnificPopup({
    delegate: 'a',
    type: 'image',
    image: {
      // titleSrc: 'title'
      titleSrc: function(item) {
        return item.el.find('img').attr('alt');
      }
    },
    callbacks: {
      open: function() {
        $('.gallery button.mfp-arrow-left').append('<i class="fa fa-chevron-left"></i>');
        $('.gallery button.mfp-arrow-right').append('<i class="fa fa-chevron-right"></i>');
      }
    }
  });

  // 年間行事スライダー
  $('.features #year .season .slide ul').slick({
    autoplay:true,
    arrows: true,
    autoplaySpeed:5000,
    pauseOnHover: false,
    speed: 2000
  });

  // フォトギャラリー：ポップアップ
  $('.gallery-popup #photos').magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {  //ギャラリーオプション
      enabled:true,
    },
    image: {
      // titleSrc: 'title'
      titleSrc: function(item) {
        return item.el.find('img').attr('alt');
      }
    },
    callbacks: {
      open: function() {
        $('.gallery button.mfp-arrow-left').append('<i class="fa fa-chevron-left"></i>');
        $('.gallery button.mfp-arrow-right').append('<i class="fa fa-chevron-right"></i>');
      }
    }
  });

  // 年齢別ギャラリー
  $('.gallery-a #photos .photo img').each(function(){
    var comment = $(this).attr('alt');
    $(this).parent().append('<span class="comment">' + comment +  '</span>');
    var photoWidth = $(this).width(),
        photoHeight = $(this).height();
    if(photoWidth < photoHeight){
      var photoPad = 100-photoWidth/2 +'px';
      $(this).parent().css('padding','0 '+ photoPad);
    }
  });

  // 給食：ポップアップ
  $('.lunch-popup .b-section').magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {  //ギャラリーオプション
      enabled:true,
    },
    image: {
      // titleSrc: 'title'
      titleSrc: function(item) {
        var titleText = item.el.next('div').html();
        // alert(titleText);
				titleText = titleText.replace('<strong>＜おやつ＞</strong><br>', '＜おやつ＞').replace(/<br>/g, '・').replace('</p><p>', '　').replace('</p>', '').replace('<p>', '');
        return titleText;
      }
    },
    callbacks: {
      open: function() {
        $('.gallery button.mfp-arrow-left').append('<i class="fa fa-chevron-left"></i>');
        $('.gallery button.mfp-arrow-right').append('<i class="fa fa-chevron-right"></i>');
      }
    }
  });
  if ($('body').hasClass('lunch')){
    // $('.lunch-popup .day:nth-child(7n-1)').hide();
    // $('.lunch-popup .day:nth-child(7n-2)').hide();
    // $('.lunch-popup .day:nth-child(7n) h3 span span').text('月');
    // $('.lunch-popup .day:nth-child(7n+1) h3 span span').text('火');
    // $('.lunch-popup .day:nth-child(7n+2) h3 span span').text('水');
    // $('.lunch-popup .day:nth-child(7n+3) h3 span span').text('木');
    // $('.lunch-popup .day:nth-child(7n+4) h3 span span').text('金');
    $('.lunch-popup .day').each(function(){
      if ($(this).children().length) {
      } else {
        $(this).addClass('empty');
      }
    });
    $('.empty').each(function(){
      if ($(this).height()<20) {
        $(this).addClass(('emptyweek'));
      }
    });
  }
});



$(window).on("load resize", function(){
  // フォトギャラリー：高さそろえ
  $('#gallery .col-3').tile();
  // 入園について：高さそろえ
  $('#main .fureai dl').tile();
  // フッター：イラスト
  var sectionWidth = $('.section').width();
  // .section　見出し背景写真
  //$('.section .h-section').css({
    //'height': Math.floor(sectionWidth/2) + 'px',
    //'padding': Math.floor(sectionWidth/4-20) + 'px 0'
  //});
    $('.features .h-bg-section .slide, .features .h-bg-section .bx-viewport').css({
      'height': Math.floor(sectionWidth/2) + 'px'
    });
   // $('.features .h-section').css({
   //   'width': Math.floor(sectionWidth) + 'px'
   // });
  // }

  // スマホ幅狭いときmenuを展開内部に
  if ($(window).width()<400) {
    $('#site-id .gnav > li:nth-child(1)').before($('#site-id .menu'));
  }
});
